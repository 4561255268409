import React from "react";
import {Edit, FormTab, TabbedForm, TextInput} from "react-admin";

const PoliceCompagnieEdit = (props) => (
    <Edit {...props}>
        <TabbedForm>
            <FormTab label="Coordonnées">
                <TextInput source="nom"/>
                <TextInput source="adresse1"/>
                <TextInput source="adresse2"/>
                <TextInput source="npa"/>
                <TextInput source="localite"/>
                <TextInput source="tel"/>
                <TextInput type="email" source="email"/>
            </FormTab>
            <FormTab label="Liens">
                <TextInput type="url" source="url"/>
                <TextInput type="url" source="urlApp"/>
                <TextInput type="url" source="urlSinistre"/>
            </FormTab>
            <FormTab label="Sinistre">
                <TextInput source="telSinistreCh"/>
                <TextInput source="telSinistreEtranger"/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default PoliceCompagnieEdit;