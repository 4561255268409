import React from "react";
import {AutocompleteInput, Create, ReferenceInput, SimpleForm, TextInput} from "react-admin";
import {priorites, statuts} from "./params";

const TacheCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="resume"/>
            <TextInput source="description" multiline label="Description de la tâche" fullWidth={true} />
            <AutocompleteInput source="statut" choices={statuts} optionText="statut" optionValue="code"/>
            <AutocompleteInput source="priorite" choices={priorites} optionText="priorite" optionValue="code"/>
            <ReferenceInput reference="profiles" source="profile"
                            filterToQuery={searchText => ({simplesearch: searchText})}>
                <AutocompleteInput optionText="nomPrenom"/>
            </ReferenceInput>
        </SimpleForm>
    </Create>
);

export default TacheCreate;
