import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {TextField} from "react-admin";

const PoliceCompagnieList = (props) => (
    <ListGuesser {...props} exporter={false} filters={false}>
        <TextField source="nom"/>
        <TextField source="adresse1"/>
        <TextField source="adresse2"/>
        <TextField source="npa"/>
        <TextField source="localite"/>
        <TextField source="tel"/>
    </ListGuesser>
);

export default PoliceCompagnieList;