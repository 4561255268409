import {Chip} from "@material-ui/core";
import React from "react";

const roles = [
    {value: 'ROLE_ADMIN', role: 'Administrateur'},
    {value: 'ROLE_SECRETARIAT', role: 'Secrétariat'},
    {value: 'ROLE_COURTIER', role: 'Courtier'},
    {value: 'ROLE_APPORTEUR_AFFAIRE', role: 'Apporteur d\'affaire'},
    {value: 'ROLE_USER', role: 'Client'}
]

const ROLES = {
    ROLE_ADMIN: "Administrateur",
    ROLE_SECRETARIAT: "Secrétariat",
    ROLE_COURTIER: "Courtier",
    ROLE_APPORTEUR_AFFAIRE: "Apporteur d'affaire",
    ROLE_USER: "Client"
};

const RolesField = ({record, source, field = null}) => {
    const data = field ? record[source][field] : record[source];
    return (
        data.map((item, idx) => (
            <Chip key={"rolefield_" + record.id + "_" + idx} label={ROLES[item]}/>
        ))
    )
};

RolesField.defaultProps = {
    addLabel: true
};

export {RolesField,roles};