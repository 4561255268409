import React, {useCallback, useState} from 'react';
import {useNotify} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import FichierModal from "./FichierModal";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {dataProvider} from "../../App";
import {useFormState} from "react-final-form";

function getPropByString(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
}

const spySubscription = {values: true};

const FichierReferenceInput = props => {
    const [version, setVersion] = useState(0);
    const {values} = useFormState();
    const record = getPropByString(values, props.source)
    const [fichiers, setFichiers] = useState(record ? record.fichier : []);
    const notify = useNotify();

    const handleChange = useCallback((fichier) => {
        setVersion(version + 1);
        setFichiers([...fichiers, fichier]);
        notify("Fichier ajouté avec succès", 'success');
    }, [version, fichiers]);

    const handleDownload = (f) => {
        const id = f['@id'].split('/')[3];
        const filename = f.chemin.split('/')[f.chemin.split('/').length - 1];
        fetch(process.env.REACT_APP_DOWNLOAD_PATH + id, {
            headers: {
                Authorization: 'Bearer ' + window.localStorage.getItem('token')
            }
        })
            .then(response => response.blob())
            .then(b => {
                let a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename);
                a.click();
            });
    }

    const handleDelete = (f) => {
        if (window.confirm('Êtes vous sûr de vouloir supprimer le fichier ? Cette action est définitive.')) {
            dataProvider.delete('fichiers', {id: f["@id"]}, {}).then((res) => {
                const allFichiers = [...fichiers];
                let index = allFichiers.indexOf(f);
                if (index !== -1) {
                    allFichiers.splice(index, 1);
                    setFichiers(allFichiers);
                    notify("Fichier supprimé avec succès", 'success');
                }
            });
        }
    };

    if (record && record['@id']) {
        return (
            <div key={`fichier-reference-${record["@type"].toLowerCase()}-${record["@id"].split('/')[3]}-v-${version}`} style={{marginBottom: 30}}>
                <h4>Fichiers</h4>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell>Titre du fichier</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableHead>
                        <TableBody>
                            {fichiers.map((f, i) => {
                                const id = record["@id"].split('/')[3];

                                return (
                                    <TableRow
                                        key={`fichier-reference-${record["@type"].toLowerCase()}-${id}-${i}`}>
                                        <TableCell>{f.titre}</TableCell>
                                        <TableCell style={{textAlign: 'right'}}>
                                            <Button variant={'outlined'} color={'primary'} onClick={() => {
                                                handleDelete(f)
                                            }}>Supprimer</Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button variant={'contained'} color={'primary'} onClick={() => {
                                                handleDownload(f)
                                            }}>Télécharger</Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FichierModal onChange={handleChange} relatedRecord={record}/>
            </div>
        );
    } else {
        return (
            <>
                <h4>Fichiers</h4>
                <h6>Veuillez d'abord sauvegarder avant de pouvoir ajouter des documents</h6>
            </>
        )
    }
};

export default FichierReferenceInput;
