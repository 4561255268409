import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';

frenchMessages.ra.page.edit = '%{name}';
frenchMessages.ra.page.show = '%{name}';
frenchMessages.resources = {
    demandes: {
        name: "Demande |||| Demandes"
    },
    profiles: {
      name: "Profile |||| Profiles"
    },
    police_types: {
        name: "Modèle d'assurance |||| Modèles d'assurance"
    },
    objet_assure_types: {
        name: "Type d'objet assuré |||| Types d'objet assuré"
    },
    profile_users: {
        name: "Compte |||| Comptes"
    },
    police_compagnies: {
        name: "Compagnie |||| Compagnies"
    },
    bbcoins: {
        name: "BBCoins |||| BBCoins",
        fields: {
            libelle: "Libellé",
            montant: "Montant (CHF)",
            assure: "Assuré"
        }
    },
    taches: {
        name: "Tâche |||| Tâches",
        fields: {
            resume: "Résumé",
            statut: "Statut",
            priorite: "Priorité",
            rapporteur: "Rapportée par",
            profile: "Attribuée à"
        }
    }
};

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

export default i18nProvider;