import React from "react";
import {
    ArrayInput,
    BooleanInput,
    Create,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    TextInput
} from "react-admin";
import {CustomAddButton, CustomRemoveButton} from "../common/customButtons";
import {inputTypes} from "../dynamicField/dynamicField";

const PoliceTypeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <ReferenceInput reference="police_categories" source="category">
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <ReferenceInput reference="police_compagnies" source="compagnie">
                <SelectInput optionText="nom"/>
            </ReferenceInput>
            <ArrayInput source="infoTypes" label="Type d'informations">
                <SimpleFormIterator
                    addButton={<CustomAddButton label="Ajouter un type d'information"/>}
                    removeButton={<CustomRemoveButton label="Supprimer le type de l'information"/>}>
                    <TextInput source="name" label="Nom du champ"/>
                    <SelectInput source="type" label="Type de champ" choices={inputTypes}/>
                    <TextInput source="sortnumber" label="Ordre"/>
                    <BooleanInput source="mandatory" label="Obligatoire"/>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

export default PoliceTypeCreate;