const priorites = [
    {code: 100, priorite: 'Très haute priorité'},
    {code: 300, priorite: 'Haute priorité'},
    {code: 500, priorite: 'Moyenne priorité'},
    {code: 700, priorite: 'Basse priorité'},
    {code: 900, priorite: 'Très basse priorité'}
];

const statuts = [
    {code: 100, statut: 'À faire'},
    {code: 500, statut: 'En cours'},
    {code: 800, statut: 'En attente'},
    {code: 900, statut: 'Terminée'},
];

export {
    priorites,
    statuts
};