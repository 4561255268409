import React, {useState} from 'react';
import {Button, FileField, FileInput, FormWithRedirect, SaveButton, TextInput, useCreate, useNotify} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

function FichierModal(props) {
    const {onChange, relatedRecord} = props;
    const [showDialog, setShowDialog] = useState(false);
    const [create, {loading}] = useCreate('fichiers');
    const notify = useNotify();

    let relatedProperty = null;
    switch (relatedRecord["@type"]) {
        case "DemandeMessage":
            relatedProperty = "message";
            break;
        default:
            relatedProperty = relatedRecord['@type'].toLowerCase();
    }


    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleSubmit = async values => {
        create(
            {payload: {data: {...values, [relatedProperty]: [relatedRecord["@id"]]}}},
            {
                onSuccess: ({data}) => {
                    setShowDialog(false);
                    onChange(data);
                },
                onFailure: ({error}) => {
                    notify(error.message, 'error');
                }
            }
        );
    };

    return (
        <>
            <Button onClick={handleClick} label="Ajouter un fichier" color={props.color ?? 'primary'}>
                <IconContentAdd/>
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Créer un fichier"
            >
                <DialogTitle>Créer un fichier</DialogTitle>

                <FormWithRedirect
                    resource="fichiers"
                    save={handleSubmit}
                    render={({
                                 handleSubmitWithRedirect,
                                 pristine,
                                 saving
                             }) => (
                        <>
                            <DialogContent>
                                <TextInput
                                    source={"titre"}
                                    fullWidth
                                />
                                <FileInput source="fichier" fullWidth>
                                    <FileField source="src"/>
                                </FileInput>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel/>
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    );
}

export default FichierModal;