import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {ReferenceField, TextField} from "react-admin";

const PoliceTypeList = (props) => (
    <ListGuesser {...props} exporter={false}>
        <TextField source="name"/>
        <ReferenceField reference="police_compagnies" source="compagnie">
            <TextField source="nom"/>
        </ReferenceField>
        <ReferenceField reference="police_categories" source="category">
            <TextField source="name"/>
        </ReferenceField>
        {/*<TextField source="compagnie.nom"/>*/}
    </ListGuesser>
);

export default PoliceTypeList;