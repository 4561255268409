import jwtDecode from "jwt-decode";

export default {
    login: ({ username, password }) => {
        const request = new Request(
            process.env.REACT_APP_LOGIN_PATH,
            {
                method: "POST",
                body: JSON.stringify({ username: username, password: password }),
                headers: new Headers({ "Content-Type": "application/json" }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token, roles, ...rest }) => {
                localStorage.setItem("token", token);
                const payload = jwtDecode(token);
                localStorage.setItem("userId", payload['userId']);
                localStorage.setItem("username", payload['roles']);
                localStorage.setItem("roles", JSON.stringify(payload['roles']));
                localStorage.setItem("profileId", payload['profileId']);
                localStorage.setItem("profileName", payload['profileName']);
            });
    },
    logout: () => {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (
                !localStorage.getItem("token") ||
                new Date().getTime() / 1000 >
                jwtDecode(localStorage.getItem("token"))?.exp
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem("token");
            localStorage.removeItem("roles");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const roles = localStorage.getItem('roles');
        return roles ? Promise.resolve(JSON.parse(roles)) : Promise.reject();
    },
    getIdentity: () => {
        return {
            'id': `/profileUser/${localStorage.getItem('userId')}`,
            'fullName': localStorage.getItem('profileName')
        }
    }
};