import React from "react";
import {
    ArrayInput, AutocompleteInput,
    BooleanInput,
    Create,
    DateInput,
    FormTab, NumberInput,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from "react-admin";
import {CustomAddButton, CustomRemoveButton} from "../common/customButtons";
import {roles} from "./RolesField";

const ProfileCreate = (props) => (
    <Create {...props}>
        <TabbedForm>
            <FormTab label="Profil">
                <TextInput source="nom" label="Nom" validate={required()}/>
                <TextInput source="prenom" label="Prénom"/>
                <DateInput source="dob" label="Date de naissance"/>
                <TextInput source="adresse" label="Adresse"/>
                <TextInput source="npa" label="NPA"/>
                <TextInput source="localite" label="Localité"/>
                <SelectInput source="permissejour" label="Permis de séjour" choices={[
                    {id: '0', name: 'Aucun'},
                    {id: 'B', name: 'Permis B'},
                    {id: 'C', name: 'Permis C'},
                    {id: 'F', name: 'Permis F'},
                    {id: 'G', name: 'Permis G'},
                    {id: 'L', name: 'Permis L'},
                    {id: null, name: 'Autres'},
                ]}/>
                <TextInput source="telephone1" label="Téléphone"/>
                <TextInput source="telephone2" label="Téléphone supplémentaire"/>
                <TextInput multiline source="remarques" label="Remarques" fullWidth={true}/>
            </FormTab>


            <FormTab label="Objets assurés">
                <ArrayInput source="objetsAssures" className="collectionTitle" label="Objets assurés">
                    <SimpleFormIterator addButton={<CustomAddButton label="Ajouter un nouvel objet assuré"/>}
                                        removeButton={<CustomRemoveButton label="Supprimer l'objet assuré"/>}>
                        <h2>Objet assuré</h2>
                        <TextInput source="nom" label="Nom" validate={required()}/>
                        <ReferenceInput reference="objet_assure_types" source="type" label="Type" validate={required()}>
                            <SelectInput optionText="name"/>
                        </ReferenceInput>
                        <ArrayInput source="polices" label="Polices" className="collectionTitle">
                            <SimpleFormIterator addButton={<CustomAddButton label="Ajouter une nouvelle police"/>}
                                                removeButton={<CustomRemoveButton label="Supprimer la police"/>}>
                                <h3>Police</h3>
                                <TextInput source="numero" label="Numéro" validate={required()}/>
                                <DateInput source="echeance" label="Date d'échéance" validate={required()}/>
                                <NumberInput source="resiliation" label="Délai de résiliation (mois)" validate={required()} min="0" step="1"/>
                                <ReferenceInput source="type" reference="police_types" label="Type"
                                                validate={required()}>
                                    <SelectInput optionText="name"/>
                                </ReferenceInput>
                                <ArrayInput source="infos" label="Informations sur la police">
                                    <SimpleFormIterator
                                        addButton={<CustomAddButton label="Ajouter une information sur la police"/>}
                                        removeButton={<CustomRemoveButton
                                            label="Supprimer l'information sur la police"/>}>
                                        <TextInput source="value"/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <ArrayInput source="infos" className="collectionTitle" label="Informations sur l'objet assuré">
                            <SimpleFormIterator
                                addButton={<CustomAddButton label="Ajouter une information à l'objet assuré"/>}
                                removeButton={<CustomRemoveButton label="Supprimer l'information de l'objet assuré"/>}>
                                <ReferenceInput reference="objet_assure_info_types" source="infotype"
                                                label="Type d'information">
                                    <SelectInput optionText="name"/>
                                </ReferenceInput>
                                <TextInput source="value" label="Valeur"/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Accès">
                {localStorage.getItem("roles") && localStorage.getItem("roles").includes('ROLE_ADMIN') ?
                    [
                        <ReferenceInput source="user" reference="profile_users" label="Compte"
                                        validate={required()}
                                        filterToQuery={searchText => ({"username": searchText})}>
                            <AutocompleteInput optionText="username"/>
                        </ReferenceInput>
                    ] : null}
            </FormTab>
        </TabbedForm>
    </Create>
);

export default ProfileCreate;
