import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {TextField} from "react-admin";

const ObjetAssureTypeList = (props) => (
    <ListGuesser {...props}>
        <TextField source="name"/>
    </ListGuesser>
)

export default ObjetAssureTypeList;