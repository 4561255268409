import React from "react";
import {ReferenceField, SelectField, TextField} from "react-admin";
import {priorites, statuts} from "./params";
import {ListGuesser} from "@api-platform/admin";

const TacheList = (props) => (
    <ListGuesser {...props} exporter={false}>
        <TextField source="resume"/>
        <SelectField source="statut" choices={statuts} optionText="statut" optionValue="code"/>
        <SelectField source="priorite" choices={priorites} optionText="priorite" optionValue="code"/>
        <ReferenceField reference="profiles" source="rapporteur">
            <TextField source="nomPrenom"/>
        </ReferenceField>
        <ReferenceField reference="profiles" source="profile">
            <TextField source="nomPrenom"/>
        </ReferenceField>
    </ListGuesser>
);

export default TacheList;