import React from "react";

const inputTypes = [
    {id: 'string', name: 'Chaîne de caractère'},
    {id: 'integer', name: 'Nombre'},
    {id: 'birthday', name: 'Date de naissance'}
];

export {
    inputTypes
};