import React from "react";
import {Datagrid, Edit, ReferenceManyField, SimpleForm, TextInput, TextField, EditButton} from "react-admin";
import AddNewButton from "../common/addNewButton";

const ObjetAssureTypeEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name"/>
            <ReferenceManyField reference="objet_assure_info_types" target="objetassuretype" label="Informations du type">
                <Datagrid>
                    <TextField source="name" label="Nom de l'information"/>
                    <TextField source="type" label="Type d'information"/>
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
            <AddNewButton label="Ajouter une nouvelle information pour le type" createPath="/objet_assure_info_types/create" property="objetassuretype"/>
        </SimpleForm>
    </Edit>
)

export default ObjetAssureTypeEdit;