import React from "react";
import {CreateGuesser} from "@api-platform/admin";
import {BooleanInput, TextInput, SelectArrayInput} from "react-admin";
import {roles} from "./RolesField";

const ProfileUserCreate = (props) => (
    <CreateGuesser {...props}>
        <TextInput source="username" label="Nom d'utilisateur"/>
        <TextInput source="email" type="email" label="E-mail"/>
        <TextInput source="password" type="password" label="Mot de passe"/>
        <SelectArrayInput label="Rôles" source="roles" choices={roles} optionValue='value' optionText='role'/>
        <BooleanInput source="enabled"/>
    </CreateGuesser>
);

export default ProfileUserCreate;