import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {TextField,BooleanField} from "react-admin";
import {RolesField} from "./RolesField";

const ProfileUserList = (props) => (
    <ListGuesser {...props} exporter={false}>
        <TextField source="username" label="Nom d'utilisateur"/>
        <TextField source="email" label="E-mail"/>
        <BooleanField source="enabled" label="Activé ?"/>
        <RolesField source="roles" label="Rôles" />
    </ListGuesser>
);

export default ProfileUserList;