import React, {useState} from "react";
import {Edit, SelectInput, SimpleForm, TextField, TextInput, Toolbar, useNotify, useRefresh} from "react-admin";
import {demandeStatuts} from "./status";
import {Button, InputLabel, Link} from "@material-ui/core";
import {dataProvider} from "../../App";
import FichierModal from "../fichier/FichierModal";

const ConversationField = (props) => {
    const {record} = props;
    const [messages, setMessages] = useState(record['messages']);
    const [version, setVersion] = useState(0);
    const IdCreateurDemande = record['createur']['@id'];
    const notify = useNotify();

    const handleChange = (fichier) => {
        let msgs = messages;
        msgs.forEach((m, i) => {
            if (m['@id'] === fichier.message[0]) {
               msgs[i].fichier.push(fichier);
           }
        });

        setMessages(msgs);
        setVersion(version + 1)
    }

    const handleDownload = (f) => {
        const id = f['@id'].split('/')[3];
        const filename = f.chemin.split('/')[f.chemin.split('/').length - 1];
        fetch(process.env.REACT_APP_DOWNLOAD_PATH + id, {
            headers: {
                Authorization: 'Bearer ' + window.localStorage.getItem('token')
            }
        })
            .then(response => response.blob())
            .then(b => {
                let a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename);
                a.click();
            });
    }

    return (
        <div key={`demande-edit-v-${version}`}>
            <InputLabel shrink={true}>Conversation</InputLabel>
            {messages.map((msg) => {
                const isCreateurDemande = msg['auteur'] ? IdCreateurDemande === msg['auteur']['@id'] : false;
                const currentProfileId = '/api/profiles/' + window.localStorage.getItem('profileId');
                const isAuteurMessage = msg['auteur'] ? (msg['auteur']['@id'] === currentProfileId || msg['auteur'] === currentProfileId) : false;

                return (
                    <div style={{
                        backgroundColor: isCreateurDemande ? 'var(--primary)' : 'var(--secondary)',
                        marginTop: ".5em",
                        marginLeft: isCreateurDemande ? '0' : 'auto',
                        marginRight: isCreateurDemande ? 'auto' : '0',
                        color: 'white',
                        padding: ' .8em',
                        borderRadius: isCreateurDemande ? '0em .8em .8em .8em' : '.8em 0em .8em .8em',
                        width: '50%',
                        whiteSpace: 'pre-line'
                    }}>
                        <p style={{
                            marginTop: '0',
                            fontWeight: 'bold'
                        }}>{msg['auteur'] && msg['auteur']['nomPrenom'] ? msg['auteur']['nomPrenom'] : 'BeBroker'}</p>
                        <p style={{marginTop: '0'}}>{msg['text']}</p>
                        {
                            msg['fichier'].map((f) => {
                                return (
                                    <div>
                                        <a onClick={()=>{handleDownload(f)}} style={{color: isCreateurDemande ? "" : "var(--primary)", cursor: 'pointer'}}>{f['titre']}</a></div>
                                )
                            })
                        }
                        {isAuteurMessage && <div>
                            <FichierModal onChange={handleChange} relatedRecord={msg} color={'secondary'}/>
                        </div>}

                        <small>{new Date(msg['datetime']).toLocaleString('fr-ch')}</small>
                    </div>
                )
            })}
            <textarea rows="8" id="newMessageTextarea" style={{width: '100%', marginTop: '1em'}}/>
            <Button style={{margin: '1em 0'}} variant="contained" color="primary" onClick={(e => {
                const input = document.getElementById('newMessageTextarea');
                const text = input.value;

                if (text.length > 0) {
                    const newMessage = {
                        text: text,
                        statut: 100,
                        datetime: (new Date()).toISOString(),
                        demande: record["@id"],
                        fichier: [],
                        auteur: `/api/profiles/${window.localStorage.getItem('profileId')}`
                    };

                    dataProvider.create('demande_messages', {data: newMessage}).then((res) => {
                        setMessages([...messages, res.data]);
                        notify("Message envoyé avec succès",  "success")
                    });
                }
            })}>Envoyer le message</Button>
        </div>
    );
}

const DemandeEdit = (props) => {
    return (<Edit {...props}>
            <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton/>} redirect={false}>
                <TextInput source="titre" label="Titre de la demande"/>
                <TextField source="createur.nomPrenom" label="Demande faite par"/>
                <SelectInput source="statut" choices={demandeStatuts} optionText="statutTexte" optionValue="statutCode"/>
                <ConversationField {...props}/>
            </SimpleForm>
        </Edit>
    );
}


export default DemandeEdit;