import React from "react";
import {ListGuesser} from "@api-platform/admin";
import {ReferenceField, TextField, TextInput} from "react-admin";
import {RolesField} from "./RolesField";

const ProfileList = props => {

    const fitlers = [
        <TextInput source={'simplesearch'} label={"Recherche"} alwaysOn />
    ];

    return (
        <ListGuesser {...props} exporter={false} filters={fitlers}>
            <TextField source="nom" label="Nom"/>
            <TextField source="prenom" label="Prénom"/>
            <ReferenceField reference="profile_users" source="user" label="Email">
                <TextField source="email"/>
            </ReferenceField>
            <ReferenceField reference="profile_users" source="user" label="Rôles">
                <RolesField source="roles"/>
            </ReferenceField>

        </ListGuesser>
    );
};

export default ProfileList;