import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from '@material-ui/icons/RemoveCircleOutline';

const CustomAddButton = (props) => {
    return (
        <Button size="small" {...props} style={{marginTop:"10px"}}>
            <AddIcon style={{marginRight:"8px"}} />
            {props.label}
        </Button>
    );
};

const CustomRemoveButton = (props) => {
    return (
        <Button size="small" {...props}>
            <CloseIcon style={{marginRight:"8px"}} />
            {props.label}
        </Button>
    );
};

export {
    CustomAddButton,
    CustomRemoveButton
};