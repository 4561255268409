const demandeStatuts = [
    {statutCode: 100, statutTexte: "Ouvert"},
    {statutCode: 500, statutTexte: "En attente"},
    {statutCode: 900, statutTexte: "Clos"},
];

const demandeMessageStatus = [
    {statutCode: 100, statutTexte: "Envoyé"},
    {statutCode: 900, statutTexte: "Lu"},
];

export {
    demandeStatuts,
    demandeMessageStatus
};