import React from "react";
import {Redirect, Route} from "react-router-dom";
import {
    fetchHydra as baseFetchHydra,
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    ResourceGuesser,
    useIntrospection
} from "@api-platform/admin";
import {Resource} from "react-admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import ProfileList from "./components/profile/profileList";
import ProfileEdit from "./components/profile/profileEdit";
import ProfileCreate from "./components/profile/profileCreate";
import ProfileUserCreate from "./components/profile/profileUserCreate";
import ProfileUserEdit from "./components/profile/profileUserEdit";
import {BeBrokerDashboard, BeBrokerLayout, BeBrokerLoginPage, BeBrokerTheme} from "./theme";
import i18nProvider from "./translation";
import ProfileUserList from "./components/profile/profileUserList";
import ObjetAssureTypeList from "./components/objetAssure/objetAssureTypeList";
import ObjetAssureTypeCreate from "./components/objetAssure/objetAssureTypeCreate";
import ObjetAssureTypeEdit from "./components/objetAssure/objetAssureTypeEdit";
import DemandeListe from "./components/demande/demandeList";
import DemandeEdit from "./components/demande/demandeEdit";
import PoliceTypeList from "./components/policeType/policeTypeList";
import PoliceTypeEdit from "./components/policeType/policeTypeEdit";
import PoliceTypeCreate from "./components/policeType/policeTypeCreate";
import FichierCreate from "./components/fichier/fichierCreate";
import PoliceCompagnieList from "./components/ policeCompagnie/policeCompagnieList";
import PoliceCompagnieEdit from "./components/ policeCompagnie/policeCompagnieEdit";
import PoliceCompagnieCreate from "./components/ policeCompagnie/policeCompagnieCreate";
import TacheList from "./components/tache/tacheList";
import TacheCreate from "./components/tache/tacheCreate";
import TacheEdit from "./components/tache/tacheEdit";
import BeBrokerMenu from "./menu";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import DescriptionIcon from '@material-ui/icons/Description';

const entrypoint = process.env.REACT_APP_ENTRYPOINT;

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};

const apiDocumentationParser = async (entrypoint) => {
    try {
        const {api} = await parseHydraDocumentation(entrypoint, {headers: getHeaders});
        return {api};
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");
            localStorage.removeItem("roles");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin}/>
                ],
            };
        }

        throw result;
    }
};

export const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, true);

export default () => (
    <HydraAdmin
        dataProvider={dataProvider}
        authProvider={authProvider}
        entrypoint={entrypoint}
        theme={BeBrokerTheme}
        // menu={BeBrokerMenu}
        dashboard={BeBrokerDashboard}
        title="BeBroker"
        layout={BeBrokerLayout}
        loginPage={BeBrokerLoginPage}
        i18nProvider={i18nProvider}
    >
        {permissions => [
            <Resource
                name="profiles"
                list={ProfileList}
                create={permissions.includes('ROLE_ADMIN') ? ProfileCreate : null}
                edit={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ? ProfileEdit : null}
                icon={EmojiPeopleIcon}
            />,
            <Resource
                name="taches"
                list={TacheList}
                create={TacheCreate}
                edit={TacheEdit}
                icon={AssignmentIcon}
            />,
            <Resource name="assurances"/>,
            permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ?
            <ResourceGuesser
                name="bbcoins"
                show={false}
                icon={AttachMoneyIcon}
            /> : null,
            permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ?
            <Resource
                name="demandes"
                list={DemandeListe}
                edit={DemandeEdit}
                icon={LiveHelpIcon}
            /> : null,
            <Resource name="demande_messages"/>,
            <Resource
                name="fichiers"
                create={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') || permissions.includes('ROLE_COURTIER') ? FichierCreate : null}
            />,
            <Resource name="objet_assures"/>,
            <Resource name="objet_assure_infos"/>,
            <Resource name="objet_assure_info_types"/>,
            <Resource name="objet_assure_notes"/>,
            <Resource
                name="objet_assure_types"
                list={ObjetAssureTypeList}
                edit={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ? ObjetAssureTypeEdit : null}
                create={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ? ObjetAssureTypeCreate : null}
                icon={DescriptionIcon}
            />,
            <Resource name="police"/>,
            <Resource name="police_categories"/>,
            <Resource
                name="police_compagnies"
                list={PoliceCompagnieList}
                edit={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ? PoliceCompagnieEdit : null}
                create={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ? PoliceCompagnieCreate : null}
                icon={BusinessIcon}
            />,
            <Resource name="police_infos"/>,
            <Resource name="police_info_types"/>,
            <Resource
                name="police_types"
                list={PoliceTypeList}
                edit={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ? PoliceTypeEdit : null}
                create={permissions.includes('ROLE_ADMIN') || permissions.includes('ROLE_SECRETARIAT') ? PoliceTypeCreate : null}
            />,
            <Resource name="profile_infos"/>,
            <Resource name="profile_info_types"/>,
            <Resource name="profile_logs"/>,
            <Resource name="profile_types"/>,
            permissions.includes('ROLE_ADMIN') ?
            <Resource
                name="profile_users"
                create={ProfileUserCreate}
                list={ProfileUserList}
                edit={ProfileUserEdit}
                icon={AccountCircleIcon}
            /> : null
        ]}
    </HydraAdmin>
);
