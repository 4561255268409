import {AppBar, defaultTheme, Layout, Login, useAuthProvider} from 'react-admin';
import merge from 'lodash/merge';
import red from '@material-ui/core/colors/red';
import {Typography, withStyles} from "@material-ui/core";
import React from "react";
import {Redirect} from "react-router-dom";
import BeBrokerMenu from "./menu";


const BeBrokerTheme = merge({}, defaultTheme, {
    palette: {
        primary: {
            main: '#e53012'
        },
        secondary: {
            main: '#1d71b8'
        },
        error: red,
    }
});

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};


const BeBrokerAppBar = withStyles(styles)(({classes, userMenu, ...props}) => {
    const authProvider = useAuthProvider();

    return (
        <AppBar userMenu={userMenu || !!authProvider} {...props}>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
        </AppBar>
    );
});

const BeBrokerLayout = (props) => <Layout appBar={BeBrokerAppBar} {...props} menu={BeBrokerMenu}/>;

const BeBrokerLoginPage = () => (
    <div className="loginPage">
        <img src="/BeBroker.png" alt="BeBroker Logo"/>
        <Login />
    </div>
);

const BeBrokerDashboard = () => (
    <Redirect to={"/profiles"}/>
);

export {
    BeBrokerTheme,
    BeBrokerLayout,
    BeBrokerLoginPage,
    BeBrokerDashboard
};