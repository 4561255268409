import {Button, Link} from "react-admin";
import React from "react";

/**
 *
 * @param record Enregistrement passé automatiquement
 * @param createPath Chemin pour créer l'entité lieé
 * @param property Propriété dans l'entité liée qui fera la relation avec l'entité acutelle
 * @param label Label du bouton
 * @returns {*}
 * @constructor
 */
const AddNewButton = ({relId, createPath, property, label, style = {}}) => {
    return (
    <Button
        component={Link}
        to={{
            pathname: createPath,
            search: `?${property}=[${relId}]`,
        }}
        label={label}
        style={style}
    />
)};

export default AddNewButton;
