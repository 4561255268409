import React from "react";
import {CreateGuesser, EditGuesser} from "@api-platform/admin";
import {BooleanInput, SelectArrayInput, TextInput} from "react-admin";
import {roles} from "./RolesField";

const ProfileUserEdit = (props) => (
    <EditGuesser {...props}>
        <TextInput source="username" label="Nom d'utilisateur"/>
        <TextInput source="email" type="email" label="E-mail"/>
        <TextInput source="password" type="password" label="Mot de passe"/>
        <BooleanInput source="enabled"/>
        <SelectArrayInput label="Rôles" source="roles" choices={roles} optionValue='value' optionText='role'/>
    </EditGuesser>
)

export default ProfileUserEdit;