import React from "react";
import {AutocompleteInput, Edit, ReferenceField, ReferenceInput, SimpleForm, TextField, TextInput} from "react-admin";
import {priorites, statuts} from "./params";

const TacheEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="resume"/>
            <TextInput source="description" multiline label="Description de la tâche" fullWidth={true} />
            <AutocompleteInput source="statut" choices={statuts} optionText="statut" optionValue="code"/>
            <AutocompleteInput source="priorite" choices={priorites} optionText="priorite" optionValue="code"/>
            <ReferenceField reference="profiles" source="rapporteur">
                <TextField source="nomPrenom"/>
            </ReferenceField>
            <ReferenceInput reference="profiles" source="profile"
                            filterToQuery={searchText => ({simplesearch: searchText})}>
                <AutocompleteInput optionText="nomPrenom"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);

export default TacheEdit;
